import {
  Budicon,
  IColour,
  IFilterTemplate,
  IProductFilters,
  SemanticButton,
  capitalise,
  config,
  sortProductSizes,
} from '@awdis/components'
import * as React from 'react'
import { Transition } from 'react-spring'
import { createSelector } from 'reselect'
import FilterTemplate from './components/FilterTemplate'
import {
  ComponentTitle,
  FilterActionContainer,
  FitersContainer,
} from './styles'

type Props = {
  filters: IProductFilters
  isCategory: boolean
  handleSelect: (status: boolean, data: IFilterTemplate, type: string) => void
  activeFilters: IProductFilters
  clearSelect: () => void
}

enum FiltersName {
  SelectedDataStatus = 'selectedDataStatus',
  CategoryStatus = 'categoryStatus',
  TagsStatus = 'tagsStatus',
  ColoursStatus = 'coloursStatus',
  SizesStatus = 'sizesStatus',
  FabricContentStatus = 'fabricContentStatus',
  FabricWeightStatus = 'fabricWeightStatus',
}

type State = {
  areFiltersOpen: {
    selectedDataStatus: boolean
    categoryStatus: boolean
    tagsStatus: boolean
    coloursStatus: boolean
    sizesStatus: boolean
    fabricContentStatus: boolean
    fabricWeightStatus: boolean
    [filtersName: string]: boolean
  }
  selectedData: Array<IFilterTemplate>
  filterStatus: boolean
}

class Filters extends React.Component<Props, State> {
  state = {
    areFiltersOpen: {
      categoryStatus: false,
      coloursStatus: false,
      sizesStatus: false,
      fabricContentStatus: false,
      fabricWeightStatus: false,
      selectedDataStatus: false,
      tagsStatus: false,
      sizeStatus: false,
    },
    selectedData: [] as Array<IFilterTemplate>,
    filterStatus: true,
  }

  selectSortedSizes = createSelector(
    (_: State, props: Props) => props.filters.sizes,
    (sizes = []) => {
      const orderedSizes = sortProductSizes(sizes)
      return orderedSizes
    }
  )

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    // On resize, if large screen open menu and searcher
    // else
    // close all
    const {
      areFiltersOpen: { sizeStatus },
    } = this.state

    if (!sizeStatus && window.innerWidth > 768) {
      this.setState(state => ({
        areFiltersOpen: {
          ...state.areFiltersOpen,
          sizeStatus: true,
        },
        filterStatus: false,
      }))
    }

    if (sizeStatus && window.innerWidth < 768) {
      this.setState(state => ({
        areFiltersOpen: {
          ...state.areFiltersOpen,
          sizeStatus: false,
        },
        filterStatus: true,
      }))
    }
  }

  handleOpen = (selector: FiltersName, nextState: boolean): void => {
    this.setState(state => ({
      areFiltersOpen: {
        ...state.areFiltersOpen,
        [selector]: nextState,
      },
    }))
  }

  handleClearSelectedData = () => {
    this.setState({ selectedData: [] })
  }

  getUniqueColours = (colours: Array<IFilterTemplate>) => {
    const flatColours = colours.reduce(
      (results: Array<IFilterTemplate>, item: any) => {
        const itemColours = item.colours || item.colour
        return [...results, ...itemColours]
      },
      []
    )

    const uniqueColours = flatColours.filter(
      (itemColour: IColour, pos: number, arr: []) => {
        return (
          arr.map((item: IColour) => item.id).indexOf(itemColour.id) === pos
        )
      }
    )

    return uniqueColours
  }

  handleShowFilters = () => {
    this.setState(({ filterStatus }: { filterStatus: boolean }) => {
      return { filterStatus: !filterStatus }
    })
  }

  render() {
    const {
      filters,
      activeFilters,
      handleSelect,
      clearSelect,
      isCategory,
    } = this.props as Props

    const {
      areFiltersOpen: {
        categoryStatus,
        coloursStatus,
        sizesStatus,
        fabricContentStatus,
        fabricWeightStatus,
        selectedDataStatus,
        tagsStatus,
        sizeStatus,
      },
      filterStatus,
    } = this.state

    const filterKeys = Object.keys(activeFilters)
    const activeFilterData = [] as Array<IFilterTemplate>
    filterKeys.forEach(key => activeFilterData.push(...activeFilters[key]))

    // Order sizes
    const orderedSizes = this.selectSortedSizes(this.state, this.props)

    return (
      <FitersContainer>
        <FilterActionContainer>
          <SemanticButton onClick={!sizeStatus && this.handleShowFilters}>
            <ComponentTitle>Filters</ComponentTitle>
            {!sizeStatus &&
              (filterStatus ? (
                <Budicon name="plus-ui" size="medium" />
              ) : (
                <Budicon name="minus-ui" size="medium" />
              ))}
          </SemanticButton>
        </FilterActionContainer>
        <Transition
          items={filterStatus}
          from={{ height: 0, margin: 'initial', opacity: 0 }}
          enter={{
            height: 'auto',
            opacity: 1,
          }}
          leave={{
            height: 0,
            padding: 0,
            margin: 0,
            opacity: 0,
          }}
        >
          {filterStatus =>
            !filterStatus &&
            (props => (
              <div style={props}>
                {activeFilterData && activeFilterData.length > 0 && (
                  <FilterTemplate
                    title="Active Filters"
                    open={selectedDataStatus}
                    handleOpen={() =>
                      this.handleOpen(
                        FiltersName.SelectedDataStatus,
                        !selectedDataStatus
                      )
                    }
                    data={activeFilterData}
                    handleSelect={handleSelect}
                    selectedData={activeFilterData}
                    handleClearSelectedData={clearSelect}
                    type="activeFilters"
                  />
                )}

                {isCategory && (
                  <FilterTemplate
                    title="Category"
                    open={categoryStatus}
                    handleOpen={() =>
                      this.handleOpen(
                        FiltersName.CategoryStatus,
                        !categoryStatus
                      )
                    }
                    data={filters.categories}
                    handleSelect={handleSelect}
                    selectedData={activeFilters ? activeFilters.category : []}
                    type="category"
                  />
                )}

                {filters.tags && filters.tags.length > 0 && (
                  <FilterTemplate
                    title="Tags"
                    open={tagsStatus}
                    handleOpen={() =>
                      this.handleOpen(FiltersName.TagsStatus, !tagsStatus)
                    }
                    data={filters.tags}
                    handleSelect={handleSelect}
                    selectedData={activeFilters ? activeFilters.tags : []}
                    type="tags"
                  />
                )}

                {filters.colours && filters.colours.length > 0 && (
                  <FilterTemplate
                    title={capitalise(config.localisedText.colour)}
                    open={coloursStatus}
                    handleOpen={() =>
                      this.handleOpen(FiltersName.ColoursStatus, !coloursStatus)
                    }
                    data={filters.colours}
                    handleSelect={handleSelect}
                    selectedData={activeFilters ? activeFilters.colours : []}
                    type="colours"
                  />
                )}

                {filters.sizes && filters.sizes.length > 0 && (
                  <FilterTemplate
                    title="Size"
                    open={sizesStatus}
                    handleOpen={() =>
                      this.handleOpen(FiltersName.SizesStatus, !sizesStatus)
                    }
                    data={orderedSizes}
                    handleSelect={handleSelect}
                    selectedData={activeFilters ? activeFilters.sizes : []}
                    type="sizes"
                  />
                )}

                {filters.fabricContents &&
                  filters.fabricContents.length > 0 && (
                    <FilterTemplate
                      title="Fabric Content"
                      open={fabricContentStatus}
                      handleOpen={() =>
                        this.handleOpen(
                          FiltersName.FabricContentStatus,
                          !fabricContentStatus
                        )
                      }
                      data={filters.fabricContents}
                      handleSelect={handleSelect}
                      selectedData={
                        activeFilters ? activeFilters.fabricContent : []
                      }
                      type="fabricContent"
                    />
                  )}

                {filters.fabricWeights && filters.fabricWeights.length > 0 && (
                  <FilterTemplate
                    title="Fabric Weight"
                    open={fabricWeightStatus}
                    handleOpen={() =>
                      this.handleOpen(
                        FiltersName.FabricWeightStatus,
                        !fabricWeightStatus
                      )
                    }
                    data={filters.fabricWeights}
                    handleSelect={handleSelect}
                    selectedData={
                      activeFilters ? activeFilters.fabricWeight : []
                    }
                    type="fabricWeight"
                  />
                )}
              </div>
            ))
          }
        </Transition>
      </FitersContainer>
    )
  }
}

export default Filters
