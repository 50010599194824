import { Budicon, colours } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import RouterLinkButton from '../../../RouterLinkButton'

type Props = {
  showRightNav: boolean
  url: string
}

type IconSize = 'medium' | 'large'

type BudiconProps = {
  name: string
  size: IconSize
  className?: string
  'aria-hidden'?: boolean
  'aria-label'?: string
}

const Arrows = styled(Budicon)<BudiconProps>`
  &:hover:not(:disabled) {
    color: ${colours.BLACK};
    opacity: 0.5;
  }
  transition: 0.2s all ease-in;
`

const NavLink = ({ showRightNav, url }: Props) => {
  if (!showRightNav) {
    return (
      <RouterLinkButton to={url} basic nohover>
        <Arrows name="chevron-right" size="large" />
      </RouterLinkButton>
    )
  }
  return (
    <RouterLinkButton to={url} basic nohover>
      <Arrows name="chevron-left" size="large" />
    </RouterLinkButton>
  )
}

export default NavLink
