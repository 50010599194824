import { Budicon, Button, IFilterTemplate } from '@awdis/components'
import * as React from 'react'
import { Transition } from 'react-spring'
import CheckBox from '../../../../../../components/CheckBox/index'
import {
  ColorPreview,
  FilterContainer,
  FilterHead,
  FilterHeaderExpandButton,
  FilterItemListContainer,
  FilterTitle,
  ItemLi,
  ItemUl,
  Name,
  SwatchPreview,
} from '../../styles'

type Props = {
  title: string
  open: boolean
  handleOpen: () => void
  data: Array<IFilterTemplate>
  handleSelect: (e: boolean, content: IFilterTemplate, type: string) => void
  selectedData?: Array<IFilterTemplate>
  handleClearSelectedData?: () => void
  type?: string
}

export type FilterSelection = {
  id: string
  quantity?: number
  name?: string
  hex?: string
  swatch?: string
  size?: string
}

const FilterTemplate = ({
  title,
  open,
  handleOpen,
  data,
  handleSelect,
  selectedData,
  handleClearSelectedData,
  type,
}: Props) => {
  return (
    <FilterContainer>
      <FilterHeaderExpandButton onClick={handleOpen}>
        <FilterHead>
          <FilterTitle>{title}</FilterTitle>
          {open ? (
            <Budicon name="minus-ui" size="medium" />
          ) : (
            <Budicon name="plus-ui" size="medium" />
          )}
        </FilterHead>
      </FilterHeaderExpandButton>
      <FilterItemListContainer>
        <Transition
          items={open}
          from={{ height: 0 }}
          enter={{ height: 'auto' }}
          leave={{
            height: 0,
            border: 0,
          }}
        >
          {transOpen =>
            transOpen &&
            (props => (
              <ItemUl style={props}>
                {data &&
                  data.map(item => {
                    const index = selectedData
                      ? selectedData.findIndex(x => x.id === item.id)
                      : -1
                    return (
                      <ItemLi key={item.id}>
                        <CheckBox
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSelect(
                              title === 'Active Filters'
                                ? false
                                : e.target.checked,
                              item,
                              type
                            )
                          }
                          id={item.id}
                          checked={index !== -1}
                        >
                          {item.hex && <ColorPreview color={item.hex} />}
                          {item.swatch && (
                            <SwatchPreview src={item.swatch} alt={item.name} />
                          )}{' '}
                          <Name>{item.name || item.size}</Name>
                          {/* TODO: Handle Total product */}
                          {/* <Quantity>({data.quantity})</Quantity> */}
                        </CheckBox>
                      </ItemLi>
                    )
                  })}

                {title === 'Active Filters' && (
                  <Button basic="very" onClick={handleClearSelectedData}>
                    Clear all filters
                  </Button>
                )}
              </ItemUl>
            ))
          }
        </Transition>
      </FilterItemListContainer>
    </FilterContainer>
  )
}

export default FilterTemplate
