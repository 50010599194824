import { colours, properties, SemanticButton } from '@awdis/components'
import styled from 'styled-components'

const { BLACK, PALE_GREY, GREY, NEW_LIGHT_GREY } = colours
const { ResponsiveBreakpoints } = properties

export const ProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-direction: column;
    justify-content: center;
  }
`
export const FitersContainer = styled.div`
  margin-right: 30px;
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    margin: 20px auto;
  }
`

export const FilterContainer = styled.div`
  width: 200px;
`

export const ComponentTitle = styled.p`
  font-size: 1.167rem;
  font-weight: 600;
  color: ${BLACK};
  margin-bottom: 0;
`

export const FilterHead = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterTitle = styled.p`
  font-size: 1.083rem;
  font-weight: 500;
  color: ${BLACK};
  margin: 0px;
`

export const FilterItemListContainer = styled.div`
  position: relative;
`

export const ItemUl = styled.ul`
  border-bottom: 1px solid ${NEW_LIGHT_GREY};
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`
export const ItemLi = styled.li`
  display: flex;
  align-content: center;
  margin: 8px 0;
  align-items: center;

  &:first-child {
    margin-top: 18px;
  }

  &:last-child {
    margin-bottom: 18px;
  }
`

export const ColorPreview = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props => props.color};
  border: 1px solid ${PALE_GREY};
  margin-right: 5px;
`

export const Name = styled.p`
  flex: 1 1;
  color: ${BLACK};
  margin: 0;
`

export const SwatchPreview = styled.img`
  margin: 0;
  width: 15px;
  height: 15px;
`
export const FilterHeaderExpandButton = styled(SemanticButton)`
  padding: 15px 0;
  border-bottom: 1px solid ${NEW_LIGHT_GREY};
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    border-bottom: 1px solid ${NEW_LIGHT_GREY};
  }
`
export const Quantity = styled.p`
  color: ${GREY};
  font-size: 0.8333rem;
  position: absolute;
  right: 0;
`

export const FilterActionContainer = styled.div`
  width: 217.4px;
  ${SemanticButton} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
