import * as React from 'react'
import {
  ProductGrid as DumbProductGrid,
  ProductGridProps as DumbProductGridProps,
} from '@awdis/components'
import SemanticRouterLink from '../SemanticRouterLink'
import Paginator from '../Paginator'
import RouterLinkButton from '../RouterLinkButton'

type ProductGridProps = {
  totalPages?: number
  currentFunctionPage?: number
  pathname?: string
  updatePage?: (updatePage: number) => void
}

const ProductGrid = ({
  totalPages,
  currentFunctionPage,
  pathname,
  updatePage,
  ...props
}: ProductGridProps & DumbProductGridProps) => {
  return (
    <DumbProductGrid
      {...props}
      routerLinkComp={SemanticRouterLink}
      routerLinkButtonComp={RouterLinkButton}
      hrefPropName="to"
      makeCardLinkProps={({ code }) => ({
        to: `/products/${code}`,
        fluid: true,
      })}
    >
      {totalPages && totalPages >= 1 && (
        <Paginator
          {...{
            index: currentFunctionPage,
            first: currentFunctionPage === 1,
            last: currentFunctionPage === totalPages,
            pageCount: totalPages,
            pathPrefix: pathname,
          }}
          updatePage={updatePage}
        />
      )}
    </DumbProductGrid>
  )
}

export default ProductGrid
