import * as React from 'react'
import styled from 'styled-components'
import RouterLinkButton from '../../../RouterLinkButton'

type Props = {
  index: number
  pageCount: number
  maxPagesFromIndex?: number
  pathPrefix: string
  updatePage: (updatePage: number) => void
}

const OrderedList = styled.ol`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  background: red;
  margin: auto;
  align-self: center;
  background: none;
`
const NumberItem = styled.li`
  background: black;
  color: white;
  margin-bottom: 0;
  font-weight: bold;
  padding: 1px;
  background: none;
`

const Navigation = styled.nav``

const PageList = ({
  pageCount, // Total number of pages
  index, // Selected index
  pathPrefix, // Page url
  maxPagesFromIndex = 4, // Amount of pages to show from the selected index
  updatePage,
}: Props) => {
  const pageNumbers = []

  // The total amount of Pages to show
  let pagesTotal = index + maxPagesFromIndex

  let startAt = index

  if (pagesTotal > pageCount) {
    startAt += pageCount - pagesTotal
    if (startAt <= 0) {
      startAt = 1
    }

    pagesTotal += pageCount - pagesTotal
  }

  // Create list of page numbers button to render
  for (let i = startAt; i <= pagesTotal; i += 1) {
    const url = i > 1 ? `${pathPrefix}?page=${i}` : pathPrefix
    pageNumbers.push(
      <NumberItem key={i} onClick={() => updatePage(i)}>
        <RouterLinkButton to={url} active={index === i} basic>
          {i}
        </RouterLinkButton>
      </NumberItem>
    )
  }

  return (
    <Navigation>
      <OrderedList>{pageNumbers}</OrderedList>
    </Navigation>
  )
}

export default PageList
