import {
  IFabricContent,
  IFabricWeight,
  IProduct,
  IProductCategory,
  IProductColourDetail,
  IProductFilters,
  IProductSize,
  IProductTag,
} from '@awdis/components'
import { navigate } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import ProductGrid from '../../components/ProductGrid'
import Seo from '../../components/Seo'
import { Menu } from '../../services/types/menu'
import { Social } from '../../services/types/social'
import Filters from './components/Filters/'
import { ProductWrapper } from './components/Filters/styles'

type Props = {
  pageContext: {
    group: Array<IProduct>
    index: number
    first: boolean
    last: boolean
    pageCount: number
    pathPrefix: string
    menu: Array<Menu>
    footer: Array<Menu>
    footerLogos: Array<{
      id: string
      url: string
      alt?: string
    }>
    social: Social
    logo: string
    brandName: string
    brandDescription: string
    products: Array<IProduct>
    isNewIn: boolean
    filters: IProductFilters
    page: {
      title?: string
      url?: string
    }
  }
  location: {
    href: string
    pathname: string
  }
}

type State = {
  allProducts: Array<IProduct>
  currentPage: number
  activeFilters: IProductFilters
  breakPoint: number
}

const NoResultsContainer = styled.div`
  width: 100%;
`

export default class ProductsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const {
      location: { href },
      pageContext: { products },
    } = this.props

    let result

    if (href && href.lastIndexOf('?page=')) {
      const parts = href.split('?page=')
      result = Number(parts[parts.length - 1])
    }

    this.state = {
      allProducts: products,
      currentPage: result || 1,
      activeFilters: {},
      breakPoint: 8,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    if (window.innerWidth <= 719) {
      this.setState({ breakPoint: 8 })
    } else if (window.innerWidth <= 768) {
      this.setState({ breakPoint: 9 })
    } else if (window.innerWidth <= 967) {
      this.setState({ breakPoint: 8 })
    } else if (window.innerWidth <= 1192) {
      this.setState({ breakPoint: 9 })
    } else {
      this.setState({ breakPoint: 8 })
    }
  }

  handleSelectFilter = (
    status: boolean,
    newFilter:
      | IProductCategory
      | IProductColourDetail
      | IProductTag
      | IProductSize
      | IFabricContent
      | IFabricWeight,
    type: string
  ) => {
    const { activeFilters, currentPage } = this.state
    let updatedActiveFilters = {} as IProductFilters

    if (status) {
      // Add filter :D lulz
      const dataFilter = activeFilters[type]
      if (dataFilter) {
        updatedActiveFilters = {
          ...activeFilters,
          [type]: [...dataFilter, newFilter],
        }
      } else {
        updatedActiveFilters = {
          ...activeFilters,
          [type]: [newFilter],
        }
      }
    } else {
      // Remove filter ;D omegalulz
      const id = newFilter.id
      updatedActiveFilters = activeFilters

      Object.keys(activeFilters).forEach(key => {
        const newData = activeFilters[key].filter(item => item.id !== id)

        if (newData.length) {
          updatedActiveFilters = {
            ...updatedActiveFilters,
            [key]: newData,
          }
        } else {
          // Filter is empty = remove it from the active filters
          delete updatedActiveFilters[key]
        }
      })
    }

    const productsFiltered = this.applyFilters(updatedActiveFilters)

    this.setState(
      {
        allProducts: productsFiltered,
        activeFilters: updatedActiveFilters,
        currentPage: 1,
      },
      // If user was on a different page, redirect to main page
      currentPage > 1
        ? () => {
            navigate(this.props.location.pathname)
          }
        : undefined
    )
  }

  applyFilters(filters: IProductFilters) {
    const {
      pageContext: { products },
    } = this.props

    const filterKeys = Object.keys(filters)
    const pageProducts = products

    if (!filterKeys.length) return pageProducts

    const results = pageProducts.filter((product: IProduct) => {
      return filterKeys.every(key => {
        if (!filters[key].length) {
          return true // passing an empty filter means that filter is ignored.
        }

        // filtering on sizes
        if (key === 'sizes' && product.colours) {
          // product.colours is where `sizes` are available
          return product.colours.some((field: IProductColourDetail) =>
            filters.sizes.some(filter => {
              if (field.sizes) {
                return field[key].some(
                  (size: IProductSize) => size.id === filter.id
                )
              }
            })
          )
        }

        if (product[key]) {
          const productKeys = [...product[key]] as Array<IProductColourDetail>
          if (productKeys) {
            // productKeys is an array of colours, sizes, tags or fabrics... (i.e: colours)
            // IProductColourDetail
            return productKeys.some((field: IProductColourDetail) =>
              // filters[key] is an array of colours, sizes, tags or fabrics...
              filters[key].some(filter => {
                return (
                  filter.id === field.id ||
                  (field.colour &&
                    field.colour.categories &&
                    field.colour.categories.some(cat => cat.id === filter.id))
                )
              })
            )
          }
        }
      })
    })
    return results
  }

  handleClearSelectedData = () => {
    const {
      pageContext: { products },
    } = this.props

    this.setState({
      activeFilters: {},
      allProducts: products,
    })
  }

  paginator = (items: Array<IProduct>, page: number, itemsPerPage: number) => {
    const currentFunctionPage = page || 1
    const perPage = itemsPerPage || 10
    const offset = (currentFunctionPage - 1) * perPage
    const paginatedItems = items.slice(offset).slice(0, perPage)
    const totalPages = Math.ceil(items.length / perPage)

    return {
      currentFunctionPage,
      perPage,
      totalPages,
      previous: currentFunctionPage - 1 ? currentFunctionPage - 1 : null,
      next: totalPages > currentFunctionPage ? currentFunctionPage + 1 : null,
      total: items.length,
      data: paginatedItems,
    }
  }

  updatePage = (currentPage: number) => {
    this.setState({ currentPage })
  }

  // Check if Category filter should be displayed
  isExtraFilterOptions = (pathname: string) => {
    return (
      [
        '/products/',
        '/products/new-in',
        '/products/new-in/',
        '/products/men',
        '/products/womens',
        '/products/unisex',
      ].indexOf(pathname) > -1
    )
  }

  render() {
    const {
      location: { pathname },
      pageContext: {
        menu,
        footer,
        footerLogos,
        social,
        logo,
        brandName,
        brandDescription,
        isNewIn,
        filters,
        page,
      },
    } = this.props as Props

    const { allProducts, currentPage, activeFilters, breakPoint } = this.state

    const {
      data: paginatedProducts,
      totalPages,
      currentFunctionPage,
    } = this.paginator(allProducts, currentPage, breakPoint)

    // swap the featured image and the hover image for women's pages if thea product has more than one sex
    let reversedProducts: Array<any>
    if (page.url === '/products/women') {
      reversedProducts = paginatedProducts.map(product => {
        if (product.sexes.length > 0) {
          return {
            ...product,
            featuredImage: product.hoverImage,
            hoverImage: product.featuredImage,
          }
        }
        return product
      })
    }

    return (
      <Layout
        data={{
          menu,
          footer,
          footerLogos,
          social,
          logo,
          brandName,
          brandDescription,
          isNewIn,
        }}
      >
        <Seo
          title={page.title}
          description={brandDescription}
          type="product.group"
          pathname={pathname}
        />
        <ProductWrapper>
          <Filters
            filters={filters}
            activeFilters={activeFilters}
            handleSelect={this.handleSelectFilter}
            clearSelect={this.handleClearSelectedData}
            isCategory={this.isExtraFilterOptions(pathname)}
          />

          {paginatedProducts.length > 0 ? (
            <ProductGrid
              products={reversedProducts || paginatedProducts}
              totalPages={totalPages}
              updatePage={this.updatePage}
              pathname={pathname}
              currentFunctionPage={currentFunctionPage}
              hover
            />
          ) : (
            <NoResultsContainer>
              <h2>No results</h2>
            </NoResultsContainer>
          )}
        </ProductWrapper>
      </Layout>
    )
  }
}
