import * as React from 'react'
import NavLink from './components/NavLink'
import styled from 'styled-components'
import PageList from './components/PageList'

type Props = {
  index: number
  first: boolean
  last: boolean
  pageCount: number
  pathPrefix: string
  updatePage: (updatePage: number) => void
}

const PaginatorWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  margin: 5% 0 0;
`

const NavContainer = styled.div`
  margin: 0 2.5% 0;
`

const Paginator = ({
  index,
  first,
  last,
  pageCount,
  pathPrefix,
  updatePage,
}: Props) => {
  const previousUrl =
    index - 1 <= 1 ? pathPrefix : `${pathPrefix}?page=${(index - 1).toString()}`
  const nextUrl = `${pathPrefix}?page=${(index + 1).toString()}`

  return (
    <PaginatorWrapper>
      {!first && (
        <NavContainer onClick={() => updatePage(index - 1)}>
          <NavLink showRightNav={!first} url={previousUrl} />
        </NavContainer>
      )}
      {pageCount > 1 && (
        <PageList
          {...{ index, pathPrefix, pageCount }}
          updatePage={updatePage}
        />
      )}
      {!last && (
        <NavContainer onClick={() => updatePage(index + 1)}>
          <NavLink showRightNav={last} url={nextUrl} />
        </NavContainer>
      )}
    </PaginatorWrapper>
  )
}

export default Paginator
